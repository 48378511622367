import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import jwtAxios, { setAuthToken } from "./jwt-api";
import { URL_API } from "../../endpoint";
import { parseJwt } from "../../../utility/Utils";
import { toast } from "react-hot-toast";
import { useSWRConfig } from "swr";
import dayjs from "dayjs";

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const { mutate } = useSWRConfig();
  const clearCache = () => mutate(() => true, undefined, { revalidate: false });

  useEffect(() => {
    const getAuthUser = async () => {
      const token = localStorage.getItem("cms.token");

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }

      const payload = parseJwt(token);
      const { exp } = payload ?? {};
      if (!exp || dayjs.unix(exp) < dayjs().subtract(0.5, "day")) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }

      setAuthToken(token);
      try {
        const data = await jwtAxios.get(URL_API.USER_INFORMATION, {
          params: {
            id: payload?.user?.id,
          },
        });

        const { permissionRoles } =
          (await jwtAxios.get(URL_API.ROLE.GET_ROLE, {
            params: {
              id: data?.roleId,
            },
          })) || {};

        setJWTAuthData({
          user: { ...data, permissionRoles },
          isLoading: false,
          isAuthenticated: true,
        });
      } catch (e) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
      }
    };

    getAuthUser();
  }, []);

  const signInUser = async ({ username, password }) => {
    try {
      const { access_token } = await jwtAxios.post(
        URL_API.AUTHENTICATION.LOGIN,
        {
          username,
          password,
        }
      );
      setAuthToken(access_token);
      const payload = parseJwt(access_token);
      const data = await jwtAxios.get(URL_API.USER_INFORMATION, {
        params: {
          id: payload?.user?.id,
        },
      });
      const role = await jwtAxios.get(URL_API.ROLE.GET_ROLE, {
        params: {
          id: data?.roleId,
        },
      });
      const permissionRoles = role?.permissionRoles;
      if (data?.role?.isAccessCms) {
        setJWTAuthData({
          user: { ...data, permissionRoles },
          isAuthenticated: true,
          isLoading: false,
        });
      } else {
        setJWTAuthData({
          ...firebaseData,
          isAuthenticated: false,
          isLoading: false,
        });
        toast.error("No have access to CMS");
      }
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      toast.error(error.message);
    }
  };

  const signUpUser = async ({ name, email, password }) => {
    try {
      const { data } = await jwtAxios.post("users", { name, email, password });
      localStorage.setItem("token", data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get("/auth");
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    clearCache();
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
